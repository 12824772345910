@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

html {
  scroll-behavior: smooth;
  font-family: ui-monospace, monospace;
}

.cursor {
    position: fixed;
    width: 25px;
    height: 25px;
    border: 1px solid #42bcbc;
    opacity: 0.5;
    border-radius: 50%;
    left: 0;
    top: 0;
    pointer-events: none;
    transform: translate(-50%, -50%);
}

.cursor2 {
    position: fixed;
    width: 6px;
    height: 6px;
    border: 1px solid transparent;
    background-color: #42bcbc;
    border-radius: 50%;
    left: 0;
    top: 0;
    pointer-events: none;
    transform: translate(-50%, -50%);
}

.highlight-text {
  @apply text-green
}

.highlight-bg {
  @apply bg-green
}

.home-container {
  height: calc(100vh - 4.7rem) !important;
  /* height: 80vh !important; */
}

@layer utilities {
  .text-green {
    color: #34D399; /* Replace with your desired color value */
  }
  .bg-green {
    color: #34D399; /* Replace with your desired color value */
  }
}