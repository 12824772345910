#home {
  height: 100vh;
}

.social-btn-hover {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  opacity: 0.5;
  background-color: red;
}

